import { useSnackbar } from "notistack";
import { QueryClient, useMutation, useQuery, useQueryClient } from "react-query";
import { openapi } from "~/services/openapi-client";
import { OpenApiPathParams, OpenApiQueryParams } from "~/types/openapi-params";
import { transformArrayToObject } from "~/utils";

// get loans list
const fetchLoanRequests = async ({
  skip = 0,
  limit = 50,
  sort,
  fetchOptions = {},
  ...filters
}) => {
  const { data } = await openapi.get("/api/v1/loans", {
    ...fetchOptions,
    params: {
      query: {
        skip,
        limit,
        sort,
        ...filters,
      },
    },
  });

  return data;
};

export const useLoanRequests = ({ skip, limit, sort, ...filters }) => {
  return useQuery({
    queryKey: ["super-admin", "loan-requests", { skip, limit, sort, filters }],
    queryFn: () => fetchLoanRequests({ skip, limit, sort, ...filters }),
    keepPreviousData: true,
    select: ({ data, meta }) => {
      const updatedLoans = data.map((loan) => {
        if (!loan.active_loan_terms) {
          return {
            ...loan,
            active_loan_terms: loan.loan_terms[0],
          };
        }
        return loan;
      });
      return {
        meta,
        data: updatedLoans,
      };
    },
  });
};

// Get single loan
const fetchLoanDetails = async ({ loanId }) => {
  const { data } = await openapi.get("/api/v1/loans/{loan_uid}", {
    params: { path: { loan_uid: loanId } },
  });
  return data;
};

const loanDetailsQuery = ({ loanId }) => ({
  queryKey: ["super-admin", "loans", loanId],
  queryFn: () => fetchLoanDetails({ loanId }),
  enabled: !!loanId,
  useErrorBoundary: true,
  select: (data) => {
    data = { ...data, loan_docs_object: transformArrayToObject(data.loan_docs, "type") };
    if (!data.active_loan_terms) {
      return {
        ...data,
        active_loan_terms: data.loan_terms[0],
      };
    }

    return data;
  },
});

export const loanDetailsLoader = (queryClient: QueryClient) => {
  return async ({ params }: { params: { loanId: string } }) => {
    const query = loanDetailsQuery({
      loanId: params.loanId,
    });

    return (
      queryClient.getQueryData(query.queryKey) ?? (await queryClient.fetchQuery(query))
    );
  };
};

export const useLoanDetails = ({ loanId }) => {
  return useQuery(loanDetailsQuery({ loanId }));
};

// Assign reviewer
interface PathParams
  extends OpenApiPathParams<"post", "/api/v1/loans/{loan_uid}/assign"> {}
interface QueryParams
  extends OpenApiQueryParams<"post", "/api/v1/loans/{loan_uid}/assign"> {}

interface AssignLoanReviewer extends PathParams, QueryParams {}

export const useAssignLoanReviewer = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ loanUid, userId }: AssignLoanReviewer) => {
      const { data } = await openapi.post("/api/v1/loans/{loan_uid}/assign", {
        params: {
          path: {
            loan_uid: loanUid,
          },
          query: { user_id: userId },
        },
      });

      return data;
    },
    onSuccess: (_, { loanUid }) => {
      enqueueSnackbar("Reviewer assigned successfully", {
        variant: "success",
      });
      return queryClient.invalidateQueries(["super-admin", "loans", loanUid]);
    },
  });
};

// Update loan status
interface PostLoanDecisionPathParams
  extends OpenApiPathParams<"post", "/api/v1/loans/{loan_uid}/{approval_action}"> {}
interface PostLoanDecisionQueryParams
  extends OpenApiQueryParams<"post", "/api/v1/loans/{loan_uid}/{approval_action}"> {}

interface PostLoanDecision
  extends PostLoanDecisionPathParams,
    PostLoanDecisionQueryParams {}

export const usePostLoanDecision = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ loanUid, approvalAction }: PostLoanDecision) => {
      const { data } = await openapi.post("/api/v1/loans/{loan_uid}/{approval_action}", {
        params: {
          path: {
            loan_uid: loanUid,
            approval_action: approvalAction,
          },
        },
      });

      return data;
    },
    onSuccess: (_, { loanUid }) => {
      enqueueSnackbar("Loans status updated successfully", {
        variant: "success",
      });
      return queryClient.invalidateQueries(["super-admin", "loans", loanUid]);
    },
  });
};

// approve/reject terms
interface PostTermsDecisionPathParams
  extends OpenApiPathParams<
    "post",
    "/api/v1/loans/{loan_uid}/loan-terms/{loan_terms_id}/{approval_action}"
  > {}
interface PostTermsDecisionQueryParams
  extends OpenApiQueryParams<
    "post",
    "/api/v1/loans/{loan_uid}/loan-terms/{loan_terms_id}/{approval_action}"
  > {}

interface PostTermsDecision
  extends PostTermsDecisionPathParams,
    PostTermsDecisionQueryParams {}

export const usePostTermsDecision = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ loanUid, loanTermsId, approvalAction }: PostTermsDecision) => {
      const { data } = await openapi.post(
        "/api/v1/loans/{loan_uid}/loan-terms/{loan_terms_id}/{approval_action}",
        {
          params: {
            path: {
              loan_uid: loanUid,
              loan_terms_id: loanTermsId,
              approval_action: approvalAction,
            },
          },
        }
      );

      return data;
    },
    onSuccess: (_, { loanUid }) => {
      enqueueSnackbar("Loans terms status updated successfully", {
        variant: "success",
      });
      return queryClient.invalidateQueries(["super-admin", "loans", loanUid]);
    },
  });
};

// Get loan assignees
export const useSuperUsers = ({ search, queryOptions = {} }) => {
  return useQuery({
    queryKey: ["super-admin", "admins", { search, queryOptions }],
    queryFn: async () => {
      const { data } = await openapi.get("/api/v1/users/admins", {
        params: {
          query: {
            search,
          },
        },
      });
      return data;
    },
    keepPreviousData: true,
    ...queryOptions,
  });
};

// get loan notes
export const useLoanNotes = ({ loanUid, queryOptions = {} }) => {
  return useQuery({
    queryKey: ["super-admin", "loan-notes", { loanUid }],
    queryFn: async () => {
      const { data } = await openapi.get("/api/v1/admin/loans/{key}/notes", {
        params: {
          path: {
            key: loanUid,
          },
        },
      });
      return data;
    },
    keepPreviousData: true,
    ...queryOptions,
  });
};

// create note
export const useCreateLoanNote = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ loanUid, ...body }: any) => {
      const { data } = await openapi.post("/api/v1/admin/loans/{key}/notes", {
        params: {
          path: {
            key: loanUid,
          },
        },
        body,
      });

      return data;
    },
    onSuccess: (_, { loanUid }) => {
      enqueueSnackbar("Loans note added successfully", {
        variant: "success",
      });
      return queryClient.invalidateQueries(["super-admin", "loan-notes", { loanUid }]);
    },
  });
};

// http://localhost:8888/api/v1/loans/{loan_uid}/private-fields
