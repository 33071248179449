import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { isProduction } from "~/utils";

export const useSetFeatureFlags = () => {
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const enableParam = searchParams.get("enable");
    const featureFlags = {};

    if (enableParam) {
      enableParam.split(",").forEach((value) => {
        featureFlags[value] = true;
      });
      localStorage.setItem("featureFlags", JSON.stringify(featureFlags));
    }
  }, [location.search]);
};

interface FeatureFlags {
  loans?: boolean;
  notifications?: boolean;
}

export const getFeatureFlags = (productionONly = false) => {
  const subdomain = window.location.host.split(".")[0];
  if (productionONly && (!isProduction || subdomain.includes("navia"))) {
    return { loans: true };
  }
  return (JSON.parse(localStorage.getItem("featureFlags")) || {}) as FeatureFlags;
};

export const clearFeatureFlags = () => localStorage.removeItem("featureFlags");
export const setFeatureFlags = (flags) => {
  const featureFlags = {};
  flags.forEach((value) => {
    featureFlags[value] = true;
  });
  localStorage.setItem("featureFlags", JSON.stringify(featureFlags));
};
