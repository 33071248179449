import find from "lodash/find";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import maxBy from "lodash/maxBy";
import sumBy from "lodash/sumBy";
import { ContributionType, PayrollLineItemType } from "../types";
import { ReactComponent as AccountIcon } from "../assets/images/side-menu-employer/account.svg";
import { ReactComponent as DocumentsIcon } from "../assets/images/side-menu-employer/documents.svg";
import { ReactComponent as EmployeesIcon } from "../assets/images/side-menu-employer/employees.svg";
import { ReactComponent as PayrollIcon } from "../assets/images/side-menu-employer/payroll.svg";
import { ReactComponent as ProfileIcon } from "../assets/images/side-menu-employer/profile.svg";
import { ReactComponent as SettingsIcon } from "../assets/images/side-menu-employer/settings.svg";
import { ReactComponent as SupportIcon } from "../assets/images/side-menu-employer/support.svg";
import { ReactComponent as InvestmentsIcon } from "../assets/images/side-menu-employee/investments.svg";
import { getSignedContractsData } from "../constants";
import { getFeatureFlags } from "~/hooks/use-feature-flags";

export const disabledMenuItems = {
  denied: ["profile", "documents", "employees", "payroll", "settings"],
  pending_review: [],
  activation_pending: [],
};

export const employerMenuOptions = (t) => [
  {
    id: "main",
    name: t("dashboard:dashboard.employer.sideMenu.myDashboard"),
    href: "/company/main",
    icon: AccountIcon,
  },
  {
    id: "employees",
    name: t("dashboard:dashboard.employer.sideMenu.employees"),
    href: "/company/employees",
    icon: EmployeesIcon,
  },
  {
    id: "payroll",
    name: t("dashboard:dashboard.employer.sideMenu.payroll"),
    href: "/company/payroll",
    icon: PayrollIcon,
  },
  ...(getFeatureFlags(true)["loans"]
    ? [
        {
          id: "loans",
          name: t("dashboard:dashboard.employer.sideMenu.loans"),
          href: "/company/loans",
          icon: PayrollIcon,
        },
      ]
    : []),
  {
    id: "transactions",
    name: t("dashboard:dashboard.employer.sideMenu.transactions"),
    href: "/company/transactions",
    icon: InvestmentsIcon,
  },
  {
    id: "documents",
    name: t("dashboard:dashboard.employer.sideMenu.myDocuments"),
    href: "/company/documents",
    icon: DocumentsIcon,
  },
  {
    id: "support",
    name: t("dashboard:dashboard.employer.sideMenu.support"),
    href: "/company/support",
    icon: SupportIcon,
  },
  {
    id: "profile",
    name: t("dashboard:dashboard.employer.sideMenu.companyProfile"),
    href: "/company/profile",
    icon: ProfileIcon,
  },
  {
    id: "settings",
    name: t("dashboard:dashboard.employer.sideMenu.settings"),
    href: "/company/settings",
    icon: SettingsIcon,
  },
];

// Decide which menu options should be disabled based on the company state
export const getSideMenuOptions = (state, t) =>
  employerMenuOptions(t).map((item) => {
    if (disabledMenuItems[state]?.includes(item.id)) {
      return {
        ...item,
        disable: true,
      };
    }
    return item;
  });

export const getEmployeeContribution = (data, type) => {
  return (
    data?.employee_retirement_plans?.[0]?.employee_contributions?.find(
      (p) => p.type === type
    ) || {}
  );
};

export const getPayrollLineItems = (data, type) => {
  const filteredLineItems = data?.payroll_line_items.filter((p) => {
    if (Array.isArray(type)) {
      return type.includes(p.contribution_type);
    }
    return p.contribution_type === type;
  });

  const sum = sumBy(filteredLineItems, "amount");
  return sum || 0;
};

export const getEmployerFAQ = ({ isManual = false, t }) => {
  const manualCompanyFAQ = [
    {
      text: t("dashboard:dashboard.employer.support.faq.onboardingGuide"),
      href: "/onboarding-guide-manual",
    },
    {
      text: t("dashboard:dashboard.employer.support.faq.updatePayrollContribution"),
      href: "/your-payroll-contribution",
    },
    {
      text: t("dashboard:dashboard.employer.support.faq.addEligibleEmployees"),
      href: "/add-eligible-employees",
    },
    {
      text: t("dashboard:dashboard.employer.support.faq.terminatingAnEmployee"),
      href: "/terminating-an-employee-manual-clients",
    },
  ];
  const integratedCompanyFAQ = [
    {
      text: t("dashboard:dashboard.employer.support.faq.onboardingGuide"),
      href: "/onboarding-guide-integrated",
    },
  ];
  const commonFAQ = [
    {
      text: t("dashboard:dashboard.employer.support.faq.administrationOverview"),
      href: "/plan-administration-for",
    },

    {
      text: t("dashboard:dashboard.employer.support.faq.updateBillingInformation"),
      href: "/update-billing-information",
    },

    {
      text: t("dashboard:dashboard.employer.support.faq.updatePayrollAccountInformation"),
      href: "/update-payroll-account-information",
    },
    {
      text: t("dashboard:dashboard.employer.support.faq.updateAdmin"),
      href: "/update-company-admin",
    },
    {
      text: t("dashboard:dashboard.employer.support.faq.employerMatch"),
      href: "/what-is-an-employer-401k-match",
    },
  ];

  if (!isManual) {
    return integratedCompanyFAQ.concat(commonFAQ);
  }

  return manualCompanyFAQ.concat(commonFAQ);
};

export const calculateSummaryPlan = (data) => {
  const monthlyPerEmployeeFees = 8;
  const oneTimeSetup = 500;
  const monthlySubscriptionFee = 125;
  const monthlyEmployeeFees =
    monthlyPerEmployeeFees * (data?.target_data?.fullTimeEmployees || 0);
  const monthlyCost = monthlyEmployeeFees + monthlySubscriptionFee;
  const totalMonthlyServiceFees = (monthlyEmployeeFees + monthlySubscriptionFee) * 12;
  const firstYearCost = oneTimeSetup + totalMonthlyServiceFees;
  return {
    monthlyCost,
    firstYearCost,
  };
};

export const tableTotals = (payrollData = []) => {
  let preTaxTotal = 0;
  let rothTotal = 0;
  let employerMatchTotal = 0;
  let totalEarnings = 0;

  payrollData.forEach((payrollItem) => {
    preTaxTotal += getPayrollLineItems(payrollItem, PayrollLineItemType.preTax);
    rothTotal += getPayrollLineItems(payrollItem, PayrollLineItemType.roth);
    employerMatchTotal += getPayrollLineItems(
      payrollItem,
      PayrollLineItemType.employerMatch
    );
    totalEarnings += getPayrollLineItems(payrollItem, PayrollLineItemType.otherEarnings);
  });

  return {
    preTaxTotal,
    rothTotal,
    employerMatchTotal,
    totalEarnings,
    totalContribution: preTaxTotal + rothTotal + employerMatchTotal,
  };
};

export const formatTableData = (payrollData, employees) => {
  if (!payrollData || !employees) {
    return [];
  }
  const employeesMap = employees.reduce((acc, employee) => {
    acc[employee.id] = employee;
    return acc;
  }, {});
  return payrollData?.map((payrollItem) => {
    const employeeId = payrollItem.employee_id;
    const employeeObject = employeesMap[employeeId];
    if (isNil(employeeObject)) {
      throw new Error("no associated employee");
    }
    return {
      name: `${employeeObject.individual.first_name} ${employeeObject.individual.last_name}`,
      email: employeeObject.email,
      preTax: {
        amount: getPayrollLineItems(payrollItem, PayrollLineItemType.preTax),
        percent:
          getEmployeeContribution(employeeObject, ContributionType.preTax)
            .contribution_percent || 0,
      },
      roth: {
        amount: getPayrollLineItems(payrollItem, PayrollLineItemType.roth),
        percent:
          getEmployeeContribution(employeeObject, ContributionType.roth)
            .contribution_percent || 0,
      },
      employerMatch: {
        amount: getPayrollLineItems(payrollItem, PayrollLineItemType.employerMatch),
      },
      otherEarnings: {
        amount: getPayrollLineItems(payrollItem, PayrollLineItemType.otherEarnings),
      },
    };
  });
};

export const formatPayrollUploadFileErrorFeedback = (errorResponse, t) => {
  const error = {};
  if (isNil(errorResponse)) {
    error.title = t("errors.fallback");
    error.details = [];
    return { error };
  }

  if (errorResponse.status === 406) {
    const detail = errorResponse.data.detail;
    return {
      error: {
        title: detail,
      },
    };
  }
  const errorObject = errorResponse?.data || {};
  if (errorResponse.status === 400) {
    const errors = errorObject.detail.map((e) => {
      const columns = e.columns.filter((c) => c !== "__root__");
      return (
        <p className="mt-3 last:mb-3">
          <strong>
            {e.row ? `Row ${e.row}` : ""}
            {e.row && columns.length ? ", " : ""}
            {columns.length
              ? `Column${columns.length > 1 ? "s" : ""} ${columns.join(", ")}:`
              : `:`}
          </strong>{" "}
          {e.message === "Unknown error" ? e.code : e.message}
        </p>
      );
    });
    return { error: { title: errorObject.body, details: errors } };
  }
  error.title = errorResponse.status >= 500 ? t("errors.server") : t("errors.fallback");
  error.details = [];
  return { error };
};

// TODO: clean this up, use object for lookup
export const combineSecuritiesWithPrices = (securities, pricesSummary, overview) => {
  if (!securities || isEmpty(pricesSummary)) {
    return [];
  }
  return securities.map((security) => {
    const tickerPrices = find(
      pricesSummary,
      (price) => price?.data?.ticker === security?.ticker
    );
    const tickerBalance =
      find(overview?.balances, (balance) => balance.ticker === security.ticker) || {};
    return {
      ...tickerBalance,
      ...tickerPrices?.data,
      ...security,
    };
  });
};

export const getPayrollDetailsBasedOnOrigin = ({ isIncomplete, location, payroll }) => {
  let payrollData;
  let status;
  let notFoundError;
  let payrollId;
  let payrollDate;
  if (isIncomplete) {
    payrollData = location.state?.payrollData || [];
    payrollDate = location.state?.payrollDate || new Date();
    payrollId = location.state?.payrollId;
  } else {
    status = payroll?.payroll_status;
    payrollData = payroll?.payroll_lines;
    payrollId = payroll?.id;
    payrollDate = payroll?.payroll_date;
  }
  return {
    payrollData,
    status,
    notFoundError,
    payrollId,
    payrollDate,
  };
};

export const getStackedChartMaxHeight = (data, keys, stackedKeys) => {
  const lineMaxValue = getChartMaxHeight(data, keys);

  function sumStacked(obj) {
    return stackedKeys.reduce((prev, current) => prev + obj[current], 0);
  }

  const stackedAreasMaxValue = sumStacked(maxBy(data, (o) => sumStacked(o)));
  return Math.max(lineMaxValue, stackedAreasMaxValue);
};

export const getChartMaxHeight = (data, keys) => {
  const maxValues = keys.map((key) => maxBy(data, key)[key]);
  return Math.max(...maxValues);
};

export const getSignedContractsList = ({ documents }, t, providerConfig) => {
  if (isNil(documents)) {
    return [];
  }
  const docsList = [];
  getSignedContractsData(t, providerConfig).forEach((doc) => {
    if (documents[doc.id]) {
      docsList.push({
        ...doc,
        fileName: documents[doc.id].objectName,
      });
    }
  });
  return docsList;
};
