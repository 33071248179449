import clsx from "clsx";
import twMerge from "~/utils/tw-merge";

const baseStyles =
  "inline-flex flex-shrink-0 flex-wrap items-center justify-center transition duration-200 ease-in-out";

const sizeStyles = {
  large: "px-10 py-[0.875rem] text-sm font-medium rounded-sm",
  medium: "px-7 py-2 text-sm font-medium rounded-sm",
  small: "px-4 py-1 text-sm font-medium rounded-sm",
};

const variantStyles = {
  primary:
    "bg-button-primary-bg text-white hover:bg-button-primary-bg_hovered disabled:bg-button-primary-bg_disabled",
  secondary:
    "bg-button-secondary-bg hover:bg-button-secondary-bg_hovered disabled:bg-button-secondary-bg_disabled border border-button-secondary-border text-button-secondary-text hover:border-button-secondary-border_hovered hover:text-button-secondary-text_hovered disabled:border-button-secondary-border_disabled disabled:text-button-secondary-text_disabled",
  tertiary:
    "bg-button-tertiary-bg hover:bg-button-tertiary-bg_hovered disabled:bg-button-tertiary-bg_disabled border border-button-tertiary-border text-button-tertiary-text hover:border-button-tertiary-border_hovered hover:text-button-tertiary-text_hovered disabled:border-button-tertiary-border_disabled disabled:text-button-tertiary-text_disabled",
  accent:
    "bg-button-accent-bg hover:bg-button-accent-bg_hovered disabled:bg-button-accent-bg_disabled border border-button-accent-border text-button-accent-text hover:border-button-accent-border_hovered hover:text-button-accent-text_hovered disabled:border-button-accent-border_disabled disabled:text-button-accent-text_disabled",
  accent_2:
    "bg-button-accent_2-bg hover:bg-button-accent_2-bg_hovered disabled:bg-button-accent_2-bg_disabled border border-button-accent_2-border text-button-accent_2-text hover:border-button-accent_2-border_hovered hover:text-button-accent_2-text_hovered disabled:border-button-accent_2-border_disabled disabled:text-button-accent_2-text_disabled",
  link: "px-0 bg-transparent font-normal border-none text-button-link-text hover:text-button-link-text_hovered disabled:text-button-link-text_disabled",
};

const NewButton = ({
  children,
  size = "large",
  variant = "secondary",
  color = "employee",
  href = null,
  startIcon = null,
  endIcon = null,
  loading = false,
  disabled = false,
  dataTheme = null,
  className = null,
  style = null,
  dataCy = null,
  onDisabledClick = () => {},
  ...props
}) => {
  const classes = twMerge(
    baseStyles,
    "relative",
    clsx(
      ((startIcon && !loading) || endIcon) && "gap-2",
      {
        [sizeStyles[size]]: size,
        [variantStyles[variant]]: variant,
        loading: loading,
      },
      className
    )
  );

  if (href) {
    return (
      <a className={classes} style={style} href={href}>
        {startIcon && startIcon}
        {children}
        {endIcon && endIcon}
      </a>
    );
  } else {
    return (
      <button
        {...props}
        onClick={(e) => {
          e.stopPropagation();
          props.onClick?.(e);
        }}
        data-theme={dataTheme}
        className={classes}
        style={style}
        disabled={disabled}
        data-cy={dataCy}
      >
        {startIcon && !loading && startIcon}
        {children}
        {endIcon && endIcon}
        {disabled && (
          <div
            className="absolute top-0 left-0 z-10 h-full w-full"
            onClick={onDisabledClick}
          />
        )}
      </button>
    );
  }
};

export default NewButton;
