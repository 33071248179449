import { QueryClient, useQuery } from "react-query";
import { openapi } from "~/services/openapi-client";
import { OpenApiPathParams } from "~/types/openapi-params";

interface FetchCompanyProps
  extends OpenApiPathParams<"get", "/api/v1/admin/companies/{company_u_id}"> {}

const fetchCompany = async ({ companyUId }: FetchCompanyProps) => {
  const { data } = await openapi.get("/api/v1/admin/companies/{company_u_id}", {
    params: { path: { company_u_id: companyUId } },
  });

  return data;
};

export const getCompanyQueryAdminKey = (companyUId) => ["companies", companyUId];

const companyQuery = ({ companyUId }: FetchCompanyProps) => ({
  queryKey: getCompanyQueryAdminKey(companyUId),
  queryFn: () => fetchCompany({ companyUId }),
  enabled: !!companyUId,
  useErrorBoundary: true,
});

export const companyLoader = (queryClient: QueryClient) => {
  return async ({ params }: { params: FetchCompanyProps }) => {
    const query = companyQuery(params);

    return (
      queryClient.getQueryData(query.queryKey) ?? (await queryClient.fetchQuery(query))
    );
  };
};

export const useCompany = ({ companyUId }: FetchCompanyProps) => {
  return useQuery(companyQuery({ companyUId }));
};

export const useCompanyNoErrorBoundary = ({ companyUId }: FetchCompanyProps) => {
  return useQuery({ ...companyQuery({ companyUId }), useErrorBoundary: false });
};
