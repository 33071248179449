import React from "react";
import Typography, { variants } from "./typography";

function Body2({ color = undefined, children = null, className = "", ...props }) {
  return (
    <Typography
      color={color}
      text={children}
      className={className}
      variant={variants.body2}
      {...props}
    />
  );
}

export default Body2;
