import { useQuery } from "react-query";
import apiInstance from "~/services/axios-instance";

const getSalesDemoContext = async () => {
  const { data } = await apiInstance.get(`/sales-demo-context`);
  return data;
};

const useSalesDemoContext = (
  individualId,
  { queryOption, config, ...filters }: any = {}
) => {
  return useQuery(["sales-demo"], () => getSalesDemoContext());
};

export default useSalesDemoContext;
