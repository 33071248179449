import { useSelector } from "react-redux";
import { currentUserSelector } from "~/redux/selectors";
import useSalesDemoContext from "~/hooks/shared/queries/use-sales-demo-context";
import { Select } from "~/components/form";
import { hijackEmployee, hijackUser } from "~/components/super-admin/user-hijack";

export const SalesDemoSwitch = ({ name }) => {
  const user = useSelector(currentUserSelector);
  const { data: salesContext } = useSalesDemoContext();
  const userType = salesContext?.current_user_type;
  const isHijacked = !!user.realUserId || user.isDemoUser;
  if (!isHijacked || !salesContext) return name;

  const options = [{ label: userType === "admin" ? name : "Admin", value: "admin" }];

  if (salesContext.employee_id)
    options.push({
      label: userType === "employee" ? name : "Employee",
      value: "employee",
    });

  return (
    <div className="">
      <Select
        value={userType}
        size="small"
        className={"font-bold"}
        variant="sidebar"
        options={options}
        setValue={(val) => {
          if (val === "admin") hijackUser({ id: salesContext.admin_id });
          else if (val === "employee") hijackEmployee({ id: salesContext.employee_id });
        }}
      ></Select>
    </div>
  );
};
