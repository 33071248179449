import { isDevEnvironment, toTitleCase } from ".";

const genErrorsUl = (errorsList) => (
  <ul
    style={{
      margin: "0rem",
      paddingInlineStart: "0.5rem",
      listStylePosition: "inside",
      ...(errorsList.length > 1
        ? { listStyleType: "decimal" }
        : { listStyleType: "none" }),
    }}
  >
    {errorsList.map((error, i) => {
      if (error.message) {
        return (
          <li
            key={i}
            style={{
              fontSize: 14,
              ...(errorsList.length > 1
                ? { marginBottom: "0.5rem", marginTop: "0.5rem" }
                : {}),
            }}
          >
            {error.message}
          </li>
        );
      }
      const location = error.loc
        .slice(-3)
        .filter((e) => !["__root__", "body"].includes(`${e}`.toLocaleLowerCase()))
        .map((e) => toTitleCase(e))
        .join(".");
      const msg = `${location === "__root__" ? "" : location + ":"} ${toTitleCase(error.msg)}.`;
      return (
        <li
          key={i}
          style={{
            lineBreak: "anywhere",
            fontSize: 14,
            ...(errorsList.length > 1
              ? { marginBottom: "0.5rem", marginTop: "0.5rem" }
              : {}),
          }}
        >
          {msg}
        </li>
      );
    })}
  </ul>
);

export const getFormattedErrors = (tError) => {
  const errorDetails = tError.response?.data?.detail || tError.detail;
  if (typeof errorDetails === "string") {
    return errorDetails;
  } else if (Array.isArray(errorDetails)) {
    return genErrorsUl(errorDetails);
  } else if (tError?.toJSON) {
    return tError.response?.status === 500
      ? "Internal server error"
      : tError.toJSON()?.message;
  } else {
    return tError.message || tError;
  }
};

export const getAppFormattedErrors = (tError) => {
  if (!isDevEnvironment()) {
    return "Something went wrong, please try again";
  } else {
    return getFormattedErrors(tError);
  }
};
