import isEmpty from "lodash/isEmpty";
import { Outlet, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Error, Loading, ProtectedRoute } from "~/components";
import { useTitle } from "./use-title";
import { isProduction } from "~/utils";
import { usePageTitle } from "./use-page-title";
import { currentUserSelector, errorSelector } from "~/redux/selectors";
import { TailwindCSSProvider } from "~/components/tailwind-css-provider";
import useScript from "~/hooks/use-script";
import BannerStateProvider from "~/components/dashboard/context/banner-context";
import EmployerDashboard from "~/views/dashboard/employer-dashboard";
import EmployeeDashboard from "~/views/dashboard/employee-dashboard";
import EmployeeOnboarding from "~/views/onboarding/employee-onboarding";
import useCompany from "~/hooks/dashboards/queries/use-company";
import DefaultLayout from "~/layouts/default-layout";
import DashboardClosed from "~/components/deconversion-blackout/dashboard-closed";
import { useOriginalUrl } from "./use-original-url";
import ErrorBoundary from "./error-boundary";
import i18n from "~/i18n";
import { getFeatureFlags } from "~/hooks/use-feature-flags";

const CompanyGuard = () => {
  const user = useSelector(currentUserSelector);
  const companyId = user.company_id;
  const { isLoading: isLoadingCompany, isError } = useCompany(companyId);
  const { url: loginUrlWithOriginalUrl } = useOriginalUrl({ pathname: "/login" });
  if (!user.id) {
    return <Navigate to={loginUrlWithOriginalUrl} />;
  }
  if (user.is_superuser && document.domain === "localhost") {
    return <Navigate to="/" />;
  }
  if (!user.is_superuser && ["user"].includes(user.role)) {
    return <Navigate to="/individual/main" />;
  }

  if (!companyId || isError) {
    return (
      <DefaultLayout showProgressCircle={false}>
        <Error canGoHome={false} />
      </DefaultLayout>
    );
  }

  if (isLoadingCompany) {
    return <Loading fullScreen />;
  }

  return <Outlet />;
};

const EmployeeGuard = () => {
  const user = useSelector(currentUserSelector);
  const { data: company } = useCompany(user.company_id);
  const { company_blackout_active } = user;
  const { url: loginUrlWithOriginalUrl } = useOriginalUrl({ pathname: "/login" });

  if (!user.id) {
    return <Navigate to={loginUrlWithOriginalUrl} />;
  }

  if (user.is_superuser && document.domain === "localhost") {
    return <Navigate to="/" />;
  }
  if (!user.is_superuser && !["user"].includes(user.role)) {
    return <Navigate to="/company/main" />;
  }

  if (company_blackout_active) {
    return (
      <DashboardClosed
        startDate={user.company_blackout_start_date}
        endDate={user.company_blackout_end_date}
        company={company}
      />
    );
  }

  return <Outlet />;
};

const AppRootGuard = () => {
  const user = useSelector(currentUserSelector);
  if (!user.id) {
    return <Navigate to="/login" />;
  }
  if (!["user"].includes(user.role)) {
    return <Navigate to="/company/main" />;
  }
  return <Navigate to="/individual/main" />;
};

const AppWrapper = () => {
  const error = useSelector(errorSelector);
  const hubSpotTagInfo = {
    id: "hs-script-loader",
    src: "//js-eu1.hs-scripts.com/25204341.js",
  };
  const { title } = usePageTitle();
  useTitle(title);

  // add hubspot script tag to the html
  useScript(isProduction ? hubSpotTagInfo : {});

  if (!isEmpty(error)) {
    return (
      <DefaultLayout showProgressCircle={false}>
        <Error
          code={error.code}
          message={error.message}
          description={error.description}
          canGoHome={false}
        />
      </DefaultLayout>
    );
  }

  return (
    <TailwindCSSProvider>
      <Outlet />
    </TailwindCSSProvider>
  );
};

export const getAppRoutes = (subdomain, t) => [
  {
    element: <AppWrapper />,
    loader: async () => {
      await i18n.loadNamespaces(["dashboard"]);
      return null;
    },
    errorElement: <ErrorBoundary redirectUnAuthorized />,
    children: [
      ...(["app", "navia-app", "navia"].includes(subdomain)
        ? [
            {
              path: "/",
              element: <AppRootGuard />,
            },
          ]
        : []),
      {
        element: <CompanyGuard />,
        children: [
          {
            path: "/company",
            element: (
              <ProtectedRoute allowedRole="admin">
                <BannerStateProvider>
                  <EmployerDashboard />
                </BannerStateProvider>
              </ProtectedRoute>
            ),
            children: [
              {
                path: "main",
                handle: { title: t("common:metaTitles.employer.account") },
                async lazy() {
                  let { EmployerDashboardMain } = await import(
                    "~/views/dashboard/employer-views"
                  );
                  return { Component: EmployerDashboardMain };
                },
                children: [
                  {
                    path: "step1",
                    async lazy() {
                      let { Step1Wrapper } = await import(
                        "~/views/dashboard/employer-views"
                      );
                      return { Component: Step1Wrapper };
                    },
                  },
                  {
                    path: "step2",
                    async lazy() {
                      let { PayrollScheduleModal } = await import(
                        "~/views/dashboard/employer-views"
                      );
                      return { Component: PayrollScheduleModal };
                    },
                  },
                  {
                    path: "step3",
                    async lazy() {
                      let { PayrollPaymentModal } = await import(
                        "~/views/dashboard/employer-views"
                      );
                      return { Component: PayrollPaymentModal };
                    },
                  },
                  {
                    path: "step4",
                    async lazy() {
                      let { PaymentModalWrapper } = await import(
                        "~/views/dashboard/employer-views"
                      );
                      return { Component: PaymentModalWrapper };
                    },
                  },
                  {
                    path: "last-step",
                    async lazy() {
                      let { GreatFinishedModal } = await import(
                        "~/views/dashboard/employer-views"
                      );
                      return { Component: GreatFinishedModal };
                    },
                  },
                ],
              },
              {
                path: "profile",
                handle: { title: t("common:metaTitles.employer.profile") },
                async lazy() {
                  let { EmployerDashboardAccount } = await import(
                    "~/views/dashboard/employer-views"
                  );
                  return { Component: EmployerDashboardAccount };
                },
              },
              {
                path: "documents",
                handle: { title: t("common:metaTitles.employer.documents") },
                async lazy() {
                  let { EmployerDashboardDocuments } = await import(
                    "~/views/dashboard/employer-views"
                  );
                  return { Component: EmployerDashboardDocuments };
                },
              },
              ...(getFeatureFlags(true)["loans"]
                ? [
                    {
                      path: "loans",
                      handle: { title: t("common:metaTitles.employer.employees") },
                      async lazy() {
                        let { EmployerLoans } = await import(
                          "~/components/employer-dashboard/loans"
                        );
                        return { Component: EmployerLoans };
                      },
                    },
                  ]
                : []),
              {
                path: "employees",
                handle: { title: t("common:metaTitles.employer.employees") },
                async lazy() {
                  let { EmployerDashboardEmployees } = await import(
                    "~/views/dashboard/employer-views"
                  );
                  return { Component: EmployerDashboardEmployees };
                },
              },
              {
                path: "employees/review/:step",
                handle: {
                  title: t("common:metaTitles.employer.employeesReview"),
                },
                async lazy() {
                  let { EmployeesReview } = await import(
                    "~/views/dashboard/employer-views"
                  );
                  return { Component: EmployeesReview };
                },
              },
              {
                path: "employees/team-info",
                handle: {
                  title: t("common:metaTitles.employer.employeesTeamInfo"),
                },
                async lazy() {
                  let { TeamInfo } = await import("~/views/dashboard/employer-views");
                  return { Component: TeamInfo };
                },
              },
              {
                path: "employees/onboarding-snapshot/file",
                handle: {
                  title: t("common:metaTitles.employer.uploadCensus"),
                },
                async lazy() {
                  let { UploadCensus } = await import("~/views/dashboard/employer-views");
                  return { Component: UploadCensus };
                },
              },
              {
                path: "payroll",
                handle: { title: t("common:metaTitles.employer.payroll") },
                async lazy() {
                  let { EmployerDashboardPayroll } = await import(
                    "~/views/dashboard/employer-views"
                  );
                  return { Component: EmployerDashboardPayroll };
                },
              },
              {
                path: "payroll/settings",
                handle: {
                  title: t("common:metaTitles.employer.payrollSettings"),
                },
                async lazy() {
                  let { EmployerDashboardPayrollSettings } = await import(
                    "~/views/dashboard/employer-views"
                  );
                  return { Component: EmployerDashboardPayrollSettings };
                },
              },
              {
                path: "payroll/payroll-contribution",
                handle: {
                  title: t("common:metaTitles.employer.uploadPayroll"),
                },
                async lazy() {
                  let { EmployerDashboardPayrollContribution } = await import(
                    "~/views/dashboard/employer-views"
                  );
                  return { Component: EmployerDashboardPayrollContribution };
                },
              },
              {
                path: "payroll/payroll-contribution/:id",
                handle: {
                  title: t("common:metaTitles.employer.payrollDetails"),
                },
                async lazy() {
                  let { PayrollDetails } = await import(
                    "~/views/dashboard/employer-views"
                  );
                  return { Component: PayrollDetails };
                },
              },
              {
                path: "transactions",
                handle: {
                  title: t("common:metaTitles.employer.transactions"),
                },
                async lazy() {
                  let { EmployerDashboardTransactions } = await import(
                    "~/views/dashboard/employer-views"
                  );
                  return { Component: EmployerDashboardTransactions };
                },
              },
              {
                path: "settings",
                handle: { title: t("common:metaTitles.employer.settings") },
                children: [
                  {
                    index: true,
                    async lazy() {
                      let { EmployerDashboardSettings } = await import(
                        "~/views/dashboard/employer-views"
                      );
                      return { Component: EmployerDashboardSettings };
                    },
                  },
                  {
                    path: "demo",
                    async lazy() {
                      let { EmployerDashboardSecretSettings } = await import(
                        "~/views/dashboard/employer-views"
                      );
                      return { Component: EmployerDashboardSecretSettings };
                    },
                  },
                ],
              },
              {
                path: "support",
                handle: { title: t("common:metaTitles.employer.support") },
                async lazy() {
                  let { EmployerDashboardSupport } = await import(
                    "~/views/dashboard/employer-views"
                  );
                  return { Component: EmployerDashboardSupport };
                },
              },
            ],
          },
        ],
      },
      {
        element: <EmployeeGuard />,
        children: [
          {
            path: "/individual",
            element: (
              <ProtectedRoute allowedRole="user">
                <BannerStateProvider>
                  <EmployeeDashboard />
                </BannerStateProvider>
              </ProtectedRoute>
            ),
            children: [
              {
                path: "main",
                handle: { title: t("common:metaTitles.employee.home") },
                async lazy() {
                  let { EmployeeDashboardMain } = await import(
                    "~/views/dashboard/employee-views"
                  );
                  return { Component: EmployeeDashboardMain };
                },
              },
              {
                path: "investment",
                handle: {
                  title: t("common:metaTitles.employee.investment"),
                },
                async lazy() {
                  let { EmployeeDashboardInvestment } = await import(
                    "~/views/dashboard/employee-views"
                  );
                  return { Component: EmployeeDashboardInvestment };
                },
              },
              {
                path: "contributions",
                handle: {
                  title: t("common:metaTitles.employee.contributions"),
                },
                async lazy() {
                  let { EmployeeDashboardInvestmentContribution } = await import(
                    "~/views/dashboard/employee-views"
                  );
                  return { Component: EmployeeDashboardInvestmentContribution };
                },
              },
              {
                path: "documents",
                handle: { title: t("common:metaTitles.employee.documents") },
                async lazy() {
                  let { EmployeeDashboardDocuments } = await import(
                    "~/views/dashboard/employee-views"
                  );
                  return { Component: EmployeeDashboardDocuments };
                },
              },
              {
                path: "support",
                handle: { title: t("common:metaTitles.employee.support") },
                async lazy() {
                  let { EmployeeDashboardSupport } = await import(
                    "~/views/dashboard/employee-views"
                  );
                  return { Component: EmployeeDashboardSupport };
                },
              },
              {
                path: "settings/distribution",
                handle: {
                  title: t("common:metaTitles.employee.distribution"),
                },
                async lazy() {
                  let { EmployeeDistribution } = await import(
                    "~/views/dashboard/employee-views"
                  );
                  return { Component: EmployeeDistribution };
                },
              },
              {
                path: "settings/rmd-questionnaire",
                handle: {
                  title: t("common:metaTitles.employee.rmdQuestionnaire"),
                },
                async lazy() {
                  let { EmployeeRMDQuestionnaire } = await import(
                    "~/views/dashboard/employee-views"
                  );
                  return { Component: EmployeeRMDQuestionnaire };
                },
              },
              {
                path: "settings/rmd-review",
                handle: {
                  title: t("common:metaTitles.employee.rmdInformation"),
                },
                async lazy() {
                  let { EmployeeRMDConfirmation } = await import(
                    "~/views/dashboard/employee-views"
                  );
                  return { Component: EmployeeRMDConfirmation };
                },
              },
              {
                path: "settings/rmd",
                handle: {
                  title: t("common:metaTitles.employee.rmdInformation"),
                },
                async lazy() {
                  let { EmployeeDistribution } = await import(
                    "~/views/dashboard/employee-views"
                  );
                  return {
                    Component: () => (
                      <EmployeeDistribution
                        storageKeyPrefix={"rmd"}
                        distributionType={"rmd"}
                      />
                    ),
                  };
                },
              },
              {
                path: "settings/rollover/:id",
                handle: {
                  title: t("common:metaTitles.employee.rolloverInstructions"),
                },
                async lazy() {
                  let { ViewInstructions } = await import(
                    "~/views/dashboard/employee-views"
                  );
                  return { Component: ViewInstructions };
                },
              },
              {
                path: "settings/rollover",
                handle: { title: t("common:metaTitles.employee.rollover") },
                async lazy() {
                  let { EmployeeRollover } = await import(
                    "~/views/dashboard/employee-views"
                  );
                  return { Component: EmployeeRollover };
                },
              },
              {
                path: "settings/beneficiaries",
                handle: {
                  title: t("common:metaTitles.employee.beneficiaries"),
                },
                async lazy() {
                  let { EmployeeDashboardSettingsBeneficiaries } = await import(
                    "~/views/dashboard/employee-views"
                  );
                  return { Component: EmployeeDashboardSettingsBeneficiaries };
                },
              },
              {
                path: "settings",
                handle: { title: t("common:metaTitles.employee.settings") },
                children: [
                  {
                    index: true,
                    async lazy() {
                      let { EmployeeDashboardSettings } = await import(
                        "~/views/dashboard/employee-views"
                      );
                      return { Component: EmployeeDashboardSettings };
                    },
                  },
                  ...(getFeatureFlags(true)["loans"]
                    ? [
                        {
                          path: "loans",
                          children: [
                            {
                              path: ":type",
                              async lazy() {
                                let { EmployeeLoansPage } = await import(
                                  "~/components/employee-dashboard/loans"
                                );
                                return { Component: EmployeeLoansPage };
                              },
                            },
                            {
                              path: "details/:loanId",
                              async lazy() {
                                let { LoanDetails } = await import(
                                  "~/components/employee-dashboard/loans/loan-details"
                                );
                                return { Component: LoanDetails };
                              },
                            },
                            {
                              path: "details/:loanId/reamortization",
                              async lazy() {
                                let { ReamortizationPage } = await import(
                                  "~/components/employee-dashboard/loans/reamortization"
                                );
                                return { Component: ReamortizationPage };
                              },
                            },
                            {
                              path: "details/:loanId/payoff",
                              async lazy() {
                                let { PayoffPage } = await import(
                                  "~/components/employee-dashboard/loans/payoff"
                                );
                                return { Component: PayoffPage };
                              },
                            },
                          ],
                        },
                      ]
                    : []),
                ],
              },
            ],
          },
          {
            path: "/individual/onboarding",
            element: <EmployeeOnboarding />,
            children: [
              {
                index: true,
                element: <Navigate to="welcome" />,
              },
              {
                path: "welcome",
                async lazy() {
                  let { Welcome } = await import(
                    "~/views/onboarding/employee-onboarding-views"
                  );
                  return { Component: Welcome };
                },
              },
              {
                path: "disclosures",
                async lazy() {
                  let { Disclosures } = await import(
                    "~/views/onboarding/employee-onboarding-views"
                  );
                  return { Component: Disclosures };
                },
              },
              {
                path: "personal",
                async lazy() {
                  let { PersonalInfo } = await import(
                    "~/views/onboarding/employee-onboarding-views"
                  );
                  return { Component: PersonalInfo };
                },
                children: [
                  {
                    path: "employee",
                    async lazy() {
                      let { EmployeeInfo } = await import(
                        "~/views/onboarding/employee-onboarding-views"
                      );
                      return { Component: EmployeeInfo };
                    },
                  },
                  {
                    path: "additional",
                    async lazy() {
                      let { AdditionalInfo } = await import(
                        "~/views/onboarding/employee-onboarding-views"
                      );
                      return { Component: AdditionalInfo };
                    },
                  },
                ],
              },
              {
                path: "beneficiaries",
                async lazy() {
                  let { Beneficiaries } = await import(
                    "~/views/onboarding/employee-onboarding-views"
                  );
                  return { Component: Beneficiaries };
                },
              },
              {
                path: "investment",
                async lazy() {
                  let { Investment } = await import(
                    "~/views/onboarding/employee-onboarding-views"
                  );
                  return { Component: Investment };
                },
              },
              {
                path: "funds",
                async lazy() {
                  let { Fund } = await import(
                    "~/views/onboarding/employee-onboarding-views"
                  );
                  return { Component: Fund };
                },
              },
              {
                path: "nice-going",
                async lazy() {
                  let { EmployeeNiceGoing } = await import(
                    "~/views/onboarding/employee-onboarding-views"
                  );
                  return { Component: EmployeeNiceGoing };
                },
              },
            ],
          },
        ],
      },
    ],
  },
];
